/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { TasksParams } from '../../store/offerToolSlice';
import './tasks.scss';
import { NavLink } from 'react-router-dom';
import { GET_CONTENT_SERVICE_PATH } from '../../config/constants';
import { extractUUID } from '../../utils/fileUtils';
import ViewMoreModal from '../dashboard/Modals/ViewMoreModal';

interface Props {
  data: TasksParams[] | undefined;
}

const TableData = ({ data }: Props) => {
  const [rowData, setRowData] = useState<TasksParams[] | undefined>();
  const [showMoreModalVisible, setShowMoreModalVisible] = useState(false);
  const [showMoreModalTitle, setShowMoreModalTitle] = useState('');
  const [showMoreModalContent, setShowMoreModalContent] = useState<string[]>([]);
  const [showAsList, setShowAsList] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);
  const columnDefs = [
    {
      headerName: 'Action',
      field: 'action',
      width: 120,
      sortable: false,
      unSortIcon: false,
      cellRenderer: function (params: any) {
        return (
          <div className="action" ref={modalRef}>
            {params?.data?.offerPath?.includes('/content/contentservice/') ? (
              <a href={GET_CONTENT_SERVICE_PATH(params?.data?.offerPath)} target="_blank">
                <span>Build content</span>
              </a>
            ) : (
              <NavLink to={`/view-data-services-task?offerPath=${params?.data?.offerPath}`}>
                <span>View Offer</span>
              </NavLink>
            )}
          </div>
        );
      },
    },
    {
      headerName: 'Assigned To',
      field: 'assignee',
      width: 250,
      sortable: true,
      unSortIcon: true,
      cellRenderer: function (params: any) {
        return (
          <div className="offerId" ref={modalRef}>
            <span>{params.value}</span>
          </div>
        );
      },
    },
    {
      headerName: 'Offer Name',
      field: 'promotionName',
      width: 200,
      sortable: true,
      unSortIcon: true,
      cellRenderer: function (params: any) {
        return (
          <div className="offerId" ref={modalRef}>
            <span>{params.value}</span>
          </div>
        );
      },
    },
    {
      headerName: 'Offer ID',
      field: 'offerId',
      width: 150,
      sortable: true,
      unSortIcon: true,
    },
    {
      headerName: 'Record Type',
      field: 'recordType',
      sortable: true,
      unSortIcon: true,
      width: 140,
    },
    {
      headerName: 'Hotel',
      field: 'hotelName',
      sortable: false,
      unSortIcon: true,
      cellRenderer: function (params: any) {
        if (params?.data?.hotelName?.length > 1) {
          return (
            <div className="" ref={modalRef}>
              <a href='#' onClick={() => { setShowMoreModalVisible(true); setShowMoreModalTitle('Hotel Name(s)'); setShowAsList(true); setShowMoreModalContent(params.data.hotelName) }}>
                <span>{params.data.hotelName.length + ' Hotels in offer'}</span>
              </a>
            </div>)
        }
        else return params.data.hotelName
      },
    },
    {
      headerName: 'Spirit Code',
      field: 'spiritCode',
      sortable: false,
      unSortIcon: true,
      width: 130,
      cellRenderer: function (params: any) {
        if (params?.data?.spiritCode?.length > 1) {
          return (
            <div className="" ref={modalRef}>
              <a href='#' onClick={() => { setShowMoreModalVisible(true); setShowAsList(false); setShowMoreModalTitle('Spirit Code(s)'); setShowMoreModalContent(params.data.spiritCode) }}>
                <span>See Codes</span>
              </a>
            </div>)
        }
        else return params.data.spiritCode
      },
    },
    {
      headerName: 'Submitted Date',
      field: 'submittedDate',
      sortable: true,
      unSortIcon: true,
      width: 160
    },
  ];

  const search = useCallback(() => {
    if (data) {
      let rows: TasksParams[] = [];
      data.map((item: TasksParams) => {
        try {
          const aemdateValue = item.submittedDate;
          const date = new Date(aemdateValue);
          const day = date.getDate();
          const month = date.toLocaleString('default', { month: 'short' });
          const year = date.getFullYear();
          const formattedDate = `${day}-${month}-${year}`;
          rows.push({
            assignee: item.assignee,
            promotionName: item.promotionName,
            offerId: item.offerId,
            offerPath: item.offerPath,
            hotelName: item.hotelName,
            recordType: item.recordType,
            spiritCode: item.spiritCode,
            submittedDate: formattedDate,
          });
          return false;
        } catch (error) {}
      });
      setRowData(rows);
      rows = [];
    }
  }, [data]);

  useEffect(() => {
    search();
  }, [search, data]);

  return data ? (
    <div className="ag-theme-alpine" style={{ width: '100%', height: '600px' }}>
      <AgGridReact
        columnDefs={columnDefs}
        rowData={rowData}
        paginationPageSize={20}
        pagination={true}
        suppressMovableColumns={true}
      />
      {
        showMoreModalVisible &&
        <ViewMoreModal title={showMoreModalTitle} content={showMoreModalContent} displayAsList={showAsList} handleClose={() => setShowMoreModalVisible(false)} />
      }
    </div>
  ) : (
    <h1>No Results found!!</h1>
  );
};

export default TableData;
