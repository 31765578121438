import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Radio } from '../../components/atoms/Radio';
import { PageHeader } from '../../components/molecules/PageHeader';
import './HotelWebsiteOffer.css';
import nls from '../../nls/HTBToolBox.json';
import { Input } from '../../components/atoms/Input';
import nlsCommon from '../../nls/common.json';
import { Select } from '../../components/atoms/Select';
import { DatePickerComponent } from '../../components/molecules/datePicker';
import { useDispatch, useSelector } from 'react-redux';
import { handleChange } from '../../store/offerToolSlice';
import Banner from '../../components/atoms/Banner';
import { WarningIcon } from '../../components/Icons/WarningIcon';
import { format, parse } from 'date-fns';
import { setTimeTo9AMCST } from '../../utils/dateAndTimeUtils';
import { useLocation } from 'react-router-dom';
import { Checkbox } from '../../components/atoms/Checkbox';
import { getImageNameFromHBM, parseHBMUrl } from '../../utils/fileUtils';
import { debounce } from '../../utils/utils';
import { imageValidation } from '../../service/api';
import { isHtmlPresent, validateRTEText } from '../../utils/textUtils';
import QuillEditor from '../../utils/QuillEditor';

export const HotelWebsiteOffer: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const {
    offers: { websiteOffers, offerType },
    showError,
  } = useSelector((state: any) => state.offerTool);
  let offerTitleOptions: { label: string; value: string }[] = [
    { label: nlsCommon.CHOOSE_APPROVED, value: '' },
    { label: nls.OFFER_GRAND_OPENING, value: nls.OFFER_GRAND_OPENING },
    { label: nls.OFFER_GRAND_REOPENING, value: nls.OFFER_GRAND_REOPENING },
    { label: nls.OFFER_ANNIVERSARY, value: nls.OFFER_ANNIVERSARY },
    { label: nls.OFFER_LIMITED_TIME, value: nls.OFFER_LIMITED_TIME },
    { label: nls.OFFER_HOLIDAY, value: nls.OFFER_HOLIDAY },
    { label: nls.OFFER_FLASH_SALE, value: nls.OFFER_FLASH_SALE },
    { label: nls.OFFER_EXCLUSIVE, value: nls.OFFER_EXCLUSIVE },
    { label: nls.OFFER_GRAND_WELCOME, value: nls.OFFER_GRAND_WELCOME },
  ];
  const [hbmUrlModified, setHbmUrlModified] = useState(false);
  const [hbmImageInValid, setHbmImageInValid] = useState(false);
  const [hbmAspectRatiosAvailable, setHbmAspectRatiosAvailable] = useState(true);
  const [hbmUrl, setHbmUrl] = useState('');
  const [offerTitleHtmlPresent, setOfferTitleHtmlPresent] = useState(false);
  const [offerDescriptionHtmlPresent, setOfferDescriptionHtmlPresent] = useState(false);

  useEffect(() => {
    const from = location.state?.from;
    if (from === 'edit') {
      dispatch(handleChange({ name: 'isUpdate', value: true }));
    }
  }, []);

  useEffect(()=>{
    setHbmUrl(websiteOffers.brandManagerID);
  },[websiteOffers.brandManagerID])

  useEffect(() => {
    dispatch(
      handleChange({
        name: 'changeOfferTitle',
        value: websiteOffers.changeOfferTitle,
      })
    );
  }, []);

  useEffect(() => {
    window.utag_data.page_name = 'offertool-toolboxoffer-hotelwebsiteoffer';
  }, []);

  function handleOfferIncludedChanged(e: ChangeEvent<HTMLInputElement>): void {
    dispatch(handleChange({ name: 'isOffer', value: e.target.value === 'yes' ? true : false }));
  }

  function handleOfferTitleChanged(e: ChangeEvent<HTMLInputElement>): void {
    dispatch(
      handleChange({
        name: 'changeOfferTitle',
        value: e.target.value === 'yes' ? true : false,
      })
    );
  }

  useEffect(() => {
    dispatch(handleChange({ name: 'page', value: 7 }));
  }, []);

  const changeOfferTitleTo = (e: ChangeEvent<HTMLSelectElement>): void => {
    dispatch(handleChange({ name: 'offerTitle', value: e.target.value }));
  };

  const getMaterialsDueDate = () => {
    try {
      if (websiteOffers.materialsDueDate) {
        const materialsDueDate = parse(
          websiteOffers.materialsDueDate,
          "yyyy-MM-dd'T'HH:mm:ss.SSSX",
          new Date()
        );
        const formattedDate = format(materialsDueDate, 'MMM-dd-yyyy');
        return new Date(formattedDate);
      } else {
        return undefined;
      }
    } catch (error) {}
  };
  const languageSelection = (e: React.ChangeEvent<HTMLInputElement>, language: string) => {
    let clonedLanguageList = [...websiteOffers.languages];
    if (e.target.checked) {
      clonedLanguageList.push(language);
      dispatch(handleChange({ name: 'languages', value: clonedLanguageList }));
    }
    else {
      const index = clonedLanguageList.indexOf(language);
      clonedLanguageList.splice(index, 1);
      dispatch(handleChange({ name: 'languages', value: clonedLanguageList }));
    }
  }

  const debounceCallback = useCallback(
    debounce((value: any) => {
      const parsedUrl = parseHBMUrl(value);
      if (parsedUrl !== value) {
        setHbmUrlModified(true);
      }
      else {
        setHbmUrlModified(false);
      }
      dispatch(handleChange({ name: 'brandManagerID', value: parsedUrl }));
      setHbmUrl(parsedUrl);
      setHbmAspectRatiosAvailable(true);
      imageValidation(getImageNameFromHBM(parsedUrl)).then((imageValidationResult) => {
        let image4x3 = false;
        let image16x9 = false;
        imageValidationResult?.hits?.forEach((result: any) => {
          if (result.name.includes('4x3')) {
            image4x3 = true;
          }
          if (result.name.includes('16x9')) {
            image16x9 = true;
          }
        });
        if (image4x3 && image16x9) {
          setHbmAspectRatiosAvailable(true);
        } else {
          setHbmAspectRatiosAvailable(false);
        }

      });
    }, 500),
    []
  );
  const hbmOnChangeHandler = ({ target: { value } }: { target: { value: string } }) => {
    setHbmUrl(value);
    debounceCallback(value);
  };
  const isPriveAmexLuxOffer = offerType === 'hyatt-prive-amex-luxury-consortia-discount-toolbox';
  const isBonusPointsOffer = offerType === 'woh-bonus-points-toolbox-offer';
  const description = isPriveAmexLuxOffer ? nls.PRIVE_AMEX_WEBSITE_DESCRIPTION : isBonusPointsOffer ? '' : nls.WEBSITE_OFFER_PAGE_DESCRIPTION;
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = websiteOffers?.offerDescription;
  const shouldIncludePageLabel = isPriveAmexLuxOffer || isBonusPointsOffer ? 'Should this offer be included on the property website offers page?'
    : 'Should this offer be included in the property website offers page? (non-qualified offers only)';
  return (
    <section className="container property-website">
      <PageHeader
        title={isPriveAmexLuxOffer ? nls.PRIVE_AMEX_WEBSITE_TITLE : "Hotel Website Offers Page"}
        description={description}
      />
      {showError && (
        <div style={{ width: '75%' }}>
          <Banner title="Required Fields" message={nlsCommon.GENERAL_ERROR_MESSAGE_BELOW} />
        </div>
      )}
      <div className="property-website-info">
        <p className="p-base-normal property-text ">
          {shouldIncludePageLabel}
        </p>
        <div className="property-website-radio">
          <Radio
            id='hotel-webiste-radio-1'
            dataAttribute='hotel-webiste-radio-1-yes'
            label="Yes"
            labelClassName="p-base-medium radio-button-align"
            name="hotel-website-offer"
            value="yes"
            checked={websiteOffers.isOffer}
            onChange={handleOfferIncludedChanged}
          />
          <Radio
            id='hotel-webiste-radio-2'
            dataAttribute='hotel-webiste-radio-1-no'
            label="No"
            labelClassName="p-base-medium radio-button-align"
            name="hotel-website-offer"
            value="no"
            checked={!websiteOffers.isOffer}
            onChange={handleOfferIncludedChanged}
          />
        </div>
        {websiteOffers.isOffer && (
          <div className="no-container">
            {(!isPriveAmexLuxOffer && !isBonusPointsOffer) ?
              <>
                <p className="p-base-normal offer-title-text ">{nls.CHANGE_OFFER_TITLE}</p>
                <div className="property-website-radio">
                  <Radio
                    id='hotel-webiste-change-title-radio-1'
                    dataAttribute='hotel-webiste-change-title-radio-1-yes'
                    label={nlsCommon.YES}
                    labelClassName="p-base-medium radio-button-align"
                    name="change-offer-title"
                    value="yes"
                    checked={websiteOffers.changeOfferTitle}
                    onChange={handleOfferTitleChanged}
                  />
                  <Radio
                    id='hotel-webiste-change-title-radio-2'
                    dataAttribute='hotel-webiste-change-title-radio-1-no'
                    label={nlsCommon.NO}
                    labelClassName="p-base-medium radio-button-align"
                    name="change-offer-title"
                    value="no"
                    checked={!websiteOffers.changeOfferTitle}
                    onChange={handleOfferTitleChanged}
                  />
                </div>
              </>
              :
              <div className="property-website-info-prive">
                {
                  isPriveAmexLuxOffer &&
                  <>
                    <Input
                      id='property-website-offer-title'
                      dataAttribute='property-website-offer-title-input'
                      label="* Special Offer Title"
                      labelClassName="special-offer-title-label"
                      placeHolder=""
                      name="offerTitle"
                      value={websiteOffers.offerTitle}
                      onChange={(e) => {
                        setOfferTitleHtmlPresent(isHtmlPresent(e.target.value));
                        dispatch(handleChange({ name: e.target.name, value: e.target.value }));
                        dispatch(handleChange({ name: 'changeOfferTitle', value: true }));
                        dispatch(handleChange({ name: 'isOffer', value: true }));
                      }}
                      className="special-offer-title"
                    />

                    {offerTitleHtmlPresent && (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          marginTop: '-10px',
                          marginBottom: '10px',
                        }}
                      >
                        <div className='warning-width'><WarningIcon width={15} height={15} /></div>
                        <p style={{ fontSize: '12px', color: 'red' }}>Error: HTML styling has been identified (highlighting, bolding, headings, etc.), please remove any styling as it will affect the way your content displays to guests.</p>
                      </div>
                    )}

                    {showError && websiteOffers.offerTitle === '' && (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          marginTop: '-10px',
                          marginBottom: '10px',
                        }}
                      >
                        <WarningIcon width={15} height={15} />
                        <p style={{ fontSize: '12px', color: 'red' }}>Provide an offer title</p>
                      </div>
                    )}
                  </>}
                {isPriveAmexLuxOffer &&
                  <>
                    <span className="b-form-input__label special-offer-title-label">
                      * Special Offer Main Description
                    </span>
                    <div className='special-offer-main-desc'>
                      <QuillEditor initialContent={websiteOffers.offerDescription} onContentChange={(content: string) => {
                        setOfferDescriptionHtmlPresent(!validateRTEText(content));
                        dispatch(handleChange({ name: "offerDescription", value: content }))
                      }} />
                    </div>

                    {offerDescriptionHtmlPresent && (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: '10px',
                        marginTop: '5px'
                      }}
                    >
                      <div className='warning-width'><WarningIcon width={15} height={15} /></div>
                      <p style={{ fontSize: '12px', color: 'red' }}>Error: HTML styling has been identified (highlighting, bolding, headings, etc.), please remove any styling as it will affect the way your content displays to guests.</p>
                    </div>
                    )}
                  {showError && (tempDiv.textContent?.trim() === '' || tempDiv.textContent?.trim() === 'undefined') && (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: '10px',
                        marginTop: '5px'
                      }}
                    >
                      <WarningIcon width={15} height={15} />
                      <p style={{ fontSize: '12px', color: 'red' }}>Provide a description</p>
                    </div>
                  )}
                  </>
                }

              </div>
            }
            {!isPriveAmexLuxOffer && !isBonusPointsOffer && websiteOffers.changeOfferTitle && (
              <>
                <div className="change-offer-title-container">
                  <Select
                    id='hotel-webiste-select-title'
                    dataAttribute='hotel-webiste-select-title-input'
                    label={`* ${nls.CHANGE_OFFER_TITLE_TO}`}
                    className="offer-code-change"
                    labelClassName="p-base-normal"
                    name="offerTitle"
                    options={offerTitleOptions}
                    onChange={changeOfferTitleTo}
                    value={websiteOffers.offerTitle}
                  />
                </div>
                {showError && websiteOffers.offerTitle === '' && (
                  <div className="error-componenet">
                    <WarningIcon width={15} height={15} />
                    <p className="error-message">{nls.PROVIDE_OFFER_TITLE}</p>
                  </div>
                )}
              </>
            )}
            {!isPriveAmexLuxOffer  && !isBonusPointsOffer && <DatePickerComponent
              label={nls.MATERIALS_DUE_DATE}
              labelClassName="p-base-medium"
              placeholder="Select date"
              className="start-date-picker"
              format="MMM-dd-yyyy"
              value={getMaterialsDueDate()}
              name="blackoutStartDate"
              onChange={(date) => {
                dispatch(
                  handleChange({
                    name: 'materialsDueDate',
                    value: setTimeTo9AMCST(date.toDateString()),
                  })
                );
              }}
              minDate={new Date()}
            />}
            {isBonusPointsOffer &&
              <div className="website-offer-hbm-subtext p-base-normal">
                This offer utilizes a pre-defined title and description to be filled in by the Content Operations team. Please include your desired image below.
              </div>
            }
            <Input
              id='hotel-webiste-brand-manager'
              dataAttribute='hotel-webiste-brand-manager-input'
              label={isPriveAmexLuxOffer ? "<a href='https://assets.hyatt.com/brandmanager.html' target='_blank'>Hyatt Brand Manager</a> (HBM) Image URL"
                : "<a href='https://assets.hyatt.com/brandmanager.html' target='_blank'>Hyatt Brand Manager</a> (HBM) Image URL*"}
              labelClassName="special-offer-title-label"
              placeHolder=""
              name="brandManagerID"
              value={hbmUrl}
              onChange={hbmOnChangeHandler}
              className="special-offer-title"
            />
            {hbmUrlModified && (
              <div className="warning-component">
                <WarningIcon width={15} height={15} color={'orange'} />
                <p className="warning-message">Url have been modified</p>
              </div>
            )}
            {showError && !isPriveAmexLuxOffer && (!websiteOffers.brandManagerID || websiteOffers.brandManagerID === '') && (
              <div className="error-componenet">
                <WarningIcon width={15} height={15} />
                <p className="error-message">{nls.PROVIDE_BRAND_MANAGER_IMAGE_URL}</p>
              </div>
            )}
            {hbmImageInValid && hbmUrl.length > 0 &&(
              <div className="warning-component">
                <WarningIcon width={15} height={15} />
                <p className="error-message">The image you have selected is not valid. Please choose another image.</p>
              </div>
            )}
            {!hbmImageInValid && !hbmAspectRatiosAvailable && hbmUrl.length > 0 &&(
              <div className="warning-component">
                <WarningIcon width={15} height={15} />
                <p className="error-message">The selected image does not have both aspect ratios. Please choose another image.</p>
              </div>
            )}
            <img className='img-hbm' src={websiteOffers.brandManagerID} alt="hidden-hbm"
              onError={() => {
                if (websiteOffers.brandManagerID) {
                  dispatch(handleChange({
                    name: 'validBrandManagerID', value: false
                  }));
                  setHbmImageInValid(true);
                } else {
                  setHbmImageInValid(false);
                  dispatch(handleChange({ name: 'validBrandManagerID', value: true }));
                }
              }}
              onLoad={() => {
                setHbmImageInValid(false);
                dispatch(handleChange({ name: 'validBrandManagerID', value: true }));
              }} />
            {!isPriveAmexLuxOffer && !isBonusPointsOffer && <Checkbox
              id='hotel-webiste-translation'
              dataAttribute='hotel-webiste-translation-checkbox'
              labelClassName='translation-required'
              onChange={(e) => {
                dispatch(handleChange({ name: 'translationRequired', value: e.target.checked }));
              }}
              checked={websiteOffers.translationRequired}
              label={'Translation Required'}
            />}
            {showError && websiteOffers?.translationRequired && websiteOffers?.languages?.length === 0 && (
              <div className="inline-error">
                <WarningIcon width={15} height={15} />
                <p style={{ fontSize: '12px', color: 'red' }}>Please select atleast one language for translation</p>
              </div>
            )}
            {websiteOffers.translationRequired && <div className='translation-language-list'>
              <Checkbox
                id='hotel-webiste-translation-spanish'
                dataAttribute='hotel-webiste-translation-spanish-checkbox'
                label='Spanish (Universal)'
                checked={websiteOffers.languages.includes('es-ES')}
                onChange={(e) => {
                  languageSelection(e, 'es-ES');
                }}
              />
              <Checkbox
                id='hotel-webiste-translation-portugese'
                dataAttribute='hotel-webiste-translation-portugese-checkbox'
                label='Portuguese (Brazil)'
                checked={websiteOffers.languages.includes('pt-PT')}
                onChange={(e) => {
                  languageSelection(e, 'pt-PT');
                }}
              />
              <Checkbox
                id='hotel-webiste-translation-french'
                dataAttribute='hotel-webiste-translation-french-checkbox'
                label='French (France)'
                checked={websiteOffers.languages.includes('fr-FR')}
                onChange={(e) => {
                  languageSelection(e, 'fr-FR');
                }}
              />
              <Checkbox
                id='hotel-webiste-translation-german'
                dataAttribute='hotel-webiste-translation-german-checkbox'
                label='German'
                checked={websiteOffers.languages.includes('de-DE')}
                onChange={(e) => {
                  languageSelection(e, 'de-DE');
                }}
              />
              <Checkbox
                id='hotel-webiste-translation-russian'
                dataAttribute='hotel-webiste-translation-russian-checkbox'
                label='Russian'
                checked={websiteOffers.languages.includes('ru-RU')}
                onChange={(e) => {
                  languageSelection(e, 'ru-RU');
                }}
              />
              <Checkbox
                id='hotel-webiste-translation-korean'
                dataAttribute='hotel-webiste-translation-korean-checkbox'
                label='Korean'
                checked={websiteOffers.languages.includes('ko-KR')}
                onChange={(e) => {
                  languageSelection(e, 'ko-KR');
                }}
              />
              <Checkbox
                id='hotel-webiste-translation-japaneses'
                dataAttribute='hotel-webiste-translation-japanese-checkbox'
                label='Japanese'
                checked={websiteOffers.languages.includes('ja-JP')}
                onChange={(e) => {
                  languageSelection(e, 'ja-JP');
                }}
              />
              <Checkbox
                id='hotel-webiste-translation-sim-chinese'
                dataAttribute='hotel-webiste-translation-sim-chinese-checkbox'
                label='Simplified Chinese'
                checked={websiteOffers.languages.includes('zh-CN')}
                onChange={(e) => {
                  languageSelection(e, 'zh-CN');
                }}
              />
              <Checkbox
                id='hotel-webiste-translation-trad-chinese'
                dataAttribute='hotel-webiste-translation-trad-chinese-checkbox'
                label='Traditional Chinese'
                checked={websiteOffers.languages.includes('zh-HK')}
                onChange={(e) => {
                  languageSelection(e, 'zh-HK');
                }}
              />
            </div>
            }
          </div>
        )}
      </div>
    </section>
  );
};
