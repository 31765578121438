import React, { useEffect, useMemo, useState } from 'react';
import './reporting.css';
import { AgGridReact } from 'ag-grid-react';
import { Button } from '../../components/atoms/Button';
import { RowClassRules } from 'ag-grid-community';
import { ReportDetails } from './report-details';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { exportApprovalReport, exportDAKReport } from '../../service/api';
import { handleChange } from '../../store/offerToolSlice';
import Alert from '../../components/atoms/Alert';
import { ApprovalReport } from './approval-report';
import moment from 'moment';

export interface ApprovalReportSearchParam {
  fromDate?: number;
  toDate?: number;
  noOfDays?: string;
}

export const Reporting = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const { isRequestLoading, showError: IsError } = useSelector((state: any) => state.offerTool);
  const [requestStatus, setRequestStatus] = useState({
    status: '',
    title: '',
    message: '',
  });
  const [showError, setShowError] = useState(false);
  const [selectOption, setSelectOption] = useState('');
  const date = new Date();
  const [createdFrom, setCreatedFrom] = useState<Date>(
    new Date(date.getFullYear(), 0, 1)
  );
  const [createdTo, setCreatedTo] = useState<Date>(date);
  const [to, setTo] = useState<Date | undefined>(date);
  const [from, setFrom] = useState<Date | undefined>(
    new Date(date.getFullYear(), date.getMonth(), 1)
  );
  const [approvalReportingSearchParams, setApprovalReportingSearchParams] =
    useState<ApprovalReportSearchParam>();
  const [dakHeaders, setDakHeaders] = useState(
    'Assigned To \nOffer Submitted Date \nPromotion ID \nPromotion Name \nRecord Type \nSpirit Code'
  );
  const [offerHeaders, setOfferHeaders] = useState(
    'DS Associate: Time spent to build offer \nDS Marketing: Time spent to build offer \nOffer Approval Date \nPromotion ID'
  );

  useEffect(() => {
    window.utag_data.page_name = 'offertool-reporting';
  }, []);

  const columnDefs = [
    {
      headerName: 'Report',
      field: 'report',
      flex: 1,
    },
    {
      headerName: 'Action',
      field: 'report',
      cellRenderer: Button,
      cellRendererParams: {
        onClick: (params: any) => {
          setSelectOption(params.target.parentNode.value);
        },
        style: {
          height: '35px',
          minHeight: '35px',
          padding: '10 0 0 10',
          fontSize: '12px',
          marginTop: '5px',
        },
        label: 'OPEN REPORT',
      },
    },
  ];

  const rowData = [
    { report: 'DAK Import Special Offer' },
    { report: 'Offer Approval Time' },
    { report: 'Offer Approval Report' },
  ];

  const reset = () => {
    setCreatedFrom(new Date(date.getFullYear(), date.getMonth(), 1));
    setCreatedTo(date);
    setTo(undefined);
    setFrom(undefined);
  };

  const rowClassRules = useMemo<RowClassRules>(() => {
    return {
      // row style function
      firstRow: (params) => {
        const index = params.node.rowIndex;
        return index === 0;
      },
      secondRow: (params) => {
        const index = params.node.rowIndex;
        return index === 1;
      },
    };
  }, []);

  const onCancel = () => {
    reset();
    setSelectOption('');
  };

  const formatDate = (inputDate: string | number | Date) => {
    const date = new Date(inputDate);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const exportDAK = async () => {
    if (from && to) {
      dispatch(handleChange({ name: 'showError', value: false }));
      dispatch(handleChange({ name: 'isRequestLoading', value: true }));
      const resp = await exportDAKReport({
        createdFrom: formatDate(createdFrom),
        createdTo: formatDate(createdTo),
        submittedFrom: formatDate(from),
        submittedTo: formatDate(to),
      });
      dispatch(handleChange({ name: 'isRequestLoading', value: false }));
      if (resp.isError) {
        setRequestStatus({
          status: 'error',
          message: resp.messsage,
          title: !resp.messsage.includes('Failed') ? 'No results found' : 'Request Failed',
        });
        setShowError(true);
      } else {
        const link = document.createElement('a');
        link.href = resp.messsage;
        link.download = 'DAK-Report.xls';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } else {
      dispatch(handleChange({ name: 'showError', value: true }));
    }
  };

  const onExportApprovalReport = async () => {
    dispatch(handleChange({ name: 'isRequestLoading', value: true }));
    const reportResp = await exportApprovalReport(approvalReportingSearchParams);
    dispatch(handleChange({ name: 'isRequestLoading', value: false }));
    if (reportResp.isError) {
      setRequestStatus({
        status: 'error',
        message: reportResp.messsage,
        title: !reportResp.messsage.includes('Failed') ? 'No results found' : 'Request Failed',
      });
      setShowError(true);
    } else {
      setApprovalReportingSearchParams(undefined);
      const link = document.createElement('a');
      link.href = reportResp.messsage;
      let fileName = approvalReportingSearchParams?.noOfDays
        ? `approval-report-for-past-${approvalReportingSearchParams?.noOfDays}-days`
        : approvalReportingSearchParams?.toDate
        ? `approval-report-from-${moment(approvalReportingSearchParams?.fromDate).format(
            'DD-MM-YYYY'
          )}-to-${moment(approvalReportingSearchParams?.toDate).format('DD-MM-YYYY')}`
        : `approval-report-from-${moment(approvalReportingSearchParams?.fromDate).format(
            'DD-MM-YYYY'
          )}`;
      link.download = `${fileName}.csv`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  useEffect(() => {
    showError &&
      setTimeout(() => {
        setShowError(false);
      }, 3000);
  }, [showError]);

  return (
    <div className="reporting-main">
      <div>
        <h1>Reporting</h1>
      </div>
      {showError && (
        <Alert
          message={requestStatus.message}
          title={requestStatus.title}
          type={requestStatus.status as any}
          onClose={() => {
            setShowError(false);
          }}
        />
      )}
      <div className="componenets-main">
        {!selectOption ? (
          <div className="ag-theme-alpine" style={{ width: '100%', height: '600px' }}>
            <AgGridReact columnDefs={columnDefs} rowData={rowData} rowClassRules={rowClassRules} />
          </div>
        ) : selectOption === 'Offer Approval Report' ? (
          <ApprovalReport
            isRequestLoading={isRequestLoading}
            title={selectOption}
            onCancel={onCancel}
            onExportApprovalReport={onExportApprovalReport}
            approvalReportingSearchParams={approvalReportingSearchParams}
            setApprovalReportingSearchParams={setApprovalReportingSearchParams}
          />
        ) : (
          <ReportDetails
            title={selectOption}
            onCancel={onCancel}
            createdFrom={createdFrom}
            setCreatedFrom={setCreatedFrom}
            createdTo={createdTo}
            setCreatedTo={setCreatedTo}
            to={to}
            setTo={setTo}
            from={from}
            setFrom={setFrom}
            dakHeaders={dakHeaders}
            offerHeaders={offerHeaders}
            exportDAK={exportDAK}
            isRequestLoading={isRequestLoading}
            IsError={IsError}
          />
        )}
      </div>
    </div>
  );
};
